import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import data from "../../content/pages/categories.yaml"
import categoriesData from '../../content/products/categories.yaml'
import { Link } from "gatsby"
import "./categories.css"
import limparImageMapper from "../components/limpar-svg-images"
import CategoriesGrid from "../components/categories-grid"

const CategoriesPage = () => (
  <Layout headerColorMode="light" preFooterPreset="partners" pageTitle={ data.meta.title } enableBorderAnim>
    <section className="lp-categories-hero container text-center">
        <div className="row justify-content-center text-center">
            <div className="col-12 col-md-6">
                <h2>{ data.content.main.title }</h2>
                <p>{ data.content.main.text }</p>
                <img className="img-fluid" src={ limparImageMapper[data.content.main.certifications] }/>
            </div>
        </div>
        <CategoriesGrid/>
    </section>
  </Layout>
)

export const Head = () => <Seo title={ data.meta.title } />

export default CategoriesPage
